<template>
  <div class="d-flex">
    <div>
      <EmFlag v-if="phoneNumberFlagCode" :country-code="phoneNumberFlagCode" />
    </div>
    <div class="ml-1">
      <slot name="phone">
        {{ phoneNumberFormatted }}
      </slot>
    </div>
  </div>
</template>

<script setup>
import { parsePhoneNumber } from 'libphonenumber-js'
const { phone: phoneFilter } = useFilters()
const props = defineProps({
  phone: { type: String, required: true },
  isInternational: { type: Boolean, default: false }
})
const phoneNumberFlagCode = computed(() => {
  try {
    const parsed = parsePhoneNumber(props.phone)
    return parsed?.country.toLowerCase()
  } catch (error) {
    return null
  }
})
const phoneNumberFormatted = computed(() => phoneFilter(props.phone))
</script>
