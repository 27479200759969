<template>
  <EmBody2 v-if="phoneNumber" semi-bold class="text-neutral-lighten-2">
    {{ phoneFilter(phoneNumber) }}
  </EmBody2>
  <EmBody3 v-else bold class="text-neutral-lighten-2">
    {{ name }}
  </EmBody3>
</template>

<script setup>
const { phone: phoneFilter } = useFilters()
defineProps({
  name: { type: String, default: null },
  phoneNumber: { type: String, required: true }
})
</script>
